import { IRoute } from "../entities";
import Layout from "../features/Layout";
import Video from "../features/video/Video";

const routes: IRoute[] = [
  {
    title: "Landing",
    name: "Landing",
    path: "/",
    componentProps: {},
    component: Video,
    layout: Layout,
  },
  {
    title: "Video",
    name: "Video",
    path: "/:experienceId",
    componentProps: {},
    component: Video,
    layout: Layout,
  },
];

export default routes;
