// @ts-nocheck
// eslint-disable-file no-use-before-define
import React, { useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useParams, useSearchParams } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { ExperienceContext } from "../../contexts/experience.context";
import { iOS } from "../../utils/utils";
import Cartouche from "../effects/Cartouche/Cartouche";
import "./Video.css";
import ModalAge from "../modal/Modal";


const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [cartouche, setCartouche] = React.useState(false);
  const { options, onReady } = props;
  const { experienceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { country, setStore } = useContext(ExperienceContext);
  const [audio, setAudio] = React.useState(new Audio("https://firebasestorage.googleapis.com/v0/b/snippetgroup/o/real_madrid%2Fbirthday2023%2Fmedia%2Frm_2023_music.aac?alt=media&token=ec037fa4-a1b1-4c82-8cb6-3764d1ceb344"));
  const [play, setPlay] = React.useState(false);
  const [quarter, setQuarter] = React.useState(false);
  const [half, setHalf] = React.useState(false);
  const [threeQuarter, setThreeQuarter] = React.useState(false);
  const [end, setEnd] = React.useState(false);
  const [shared, setShared] = React.useState(
    searchParams.get("shared") === "true" ? "compartido" : "",
  );
  const [playing, setPlaying] = React.useState(false);

  const handlePlayAudio = () => {
    audio.play();
    console.log(audio)
  };

  const handlePauseAudio = () => {
    audio.pause();
  };

  const handlePlayVideo = () => {
    videoRef.current.play();
    audio.play();
  };

  const handlePauseVideo = () => {
    videoRef.current.pause();
    audio.pause();
  };

  useEffect(() => {
    if (play) {
      ReactGA.event({
        action: `play`,
        category: `play`,
        label: `${experienceId}`, // optional
      });
    }
  }, [play]);

  useEffect(() => {
    if (quarter) {
      ReactGA.event({
        action: `primercuarto`,
        category: `primercuarto`,
        label: `${experienceId}`, // optional
      });
    }
  }, [quarter]);

  useEffect(() => {
    if (half) {
      ReactGA.event({
        action: `mitad`,
        category: `mitad`,
        label: `${experienceId}`, // optional
      });
    }
  }, [half]);

  useEffect(() => {
    if (threeQuarter) {
      ReactGA.event({
        action: `trescuartos`,
        category: `trescuartos`,
        label: `${experienceId}`, // optional
      });
    }
  }, [threeQuarter]);

  useEffect(() => {
    if (end) {
      ReactGA.event({
        action: `final`,
        category: `final`,
        label: `${experienceId}`, // optional
      });
    }
  }, [end]);

  useEffect(() => {
    if (country !== "") {
      ReactGA.event({
        action: `intro_experience`,
        category: `entroenlaexperiencia`,
        label: `${experienceId}`, // optional
      });
    }
  }, [country, experienceId]);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoElement.setAttribute("webkit-playsinline", "true");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        onReady && onReady(player);

        // BigPlayButton.prototype.onClick = function () {
        //   handlePlayAudio()
        //   player.play();
        // }

        player.playsinline(true);

        player.on("ontouchstart", () => {
          if (player.paused()) {
            setPlaying(true);
            handlePlayVideo();
          } else {
            setPlaying(false);
            handlePauseVideo();
          }
        });

        player.on("play", () => {
          console.log("prueba play  ");
          if (player.currentTime() != player.duration()) {
            handlePlayAudio();
            setPlay(true);
            setPlaying(true);
          }
        });

        player.on("ended", () => {
          audio.pause();
          setPlaying(true);
        });

        player.on("pause", () => {
          console.log("prueba pause");
          audio.pause();
          if (player.currentTime() != player.duration()) {
            setPlaying(false);
            handlePauseAudio();
          }
        });

        player.on("seeked", () => {
          console.log("prueba seeking");
          console.log(player.paused());
          if (player.paused() && !iOS()) {
            audio.currentTime = player.currentTime();
            audio.pause();
          } else {
            console.log("prueba seeking 1");
            if (!iOS()) {
              console.log("prueba seeking 2");
              audio.currentTime = player.currentTime();
            }
          }
        });

        player.on("timeupdate", () => {
          /*if (
            player.currentTime() > player.duration() - 8.861333 &&
            player.duration() > 0
          ) {
            setCartouche(true);
          } else {
            setCartouche(false);
          }*/
          if (
            player.currentTime() > 1 &&
            iOS() &&
            player.currentTime() !== player.duration()
          ) {
            handlePlayAudio();
          }
          if (player.currentTime() > player.duration() / 4) {
            if (!quarter) {
              setQuarter(true);
            }
          }
          if (player.currentTime() > player.duration() / 2) {
            if (!half) {
              setHalf(true);
            }
          }
          if (player.currentTime() > 3 * (player.duration() / 4)) {
            if (!threeQuarter) {
              setThreeQuarter(true);
            }
          }
          if (player.currentTime() >= player.duration() - 0.5) {
            if (!end) {
              setEnd(true);
            }
          }
        });
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
      console.log(options.sources);
    }
  }, [options, videoRef]);

  /*useEffect(() => {
    console.log(audio);
  }, [audio]);
*/
  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  React.useEffect(() => { }, [playerRef]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        audio.pause();
        playerRef.current.pause();
      }
    };

    const handleBeforeUnload = () => {
      audio.pause();
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      audio.pause();
    };
  }, [audio]);

  return (
    <div data-vjs-player className="video-inner-wrapper">
      <div className="video-container" ref={videoRef} />
      {cartouche && <Cartouche />}
      {!playing && (
        <button
          className={"play-button"}
          onClick={() => {
            handlePlayAudio();
            playerRef.current.play();
            setPlaying(true);
          }}
        >
          <span className="play-button-icon">
            <svg
              fill="#000000"
              height="800px"
              width="800px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 60 60"
            >
              <g>
                <path
                  d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30
              c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15
              C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z"
                />
                <path
                  d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30
              S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z"
                />
              </g>
            </svg>
          </span>
        </button>
      )}
    </div>
  );
};

export default React.memo(VideoJS);
