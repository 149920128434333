import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import storage from "../../../firebase";
import { ExperienceContext } from "../../../contexts/experience.context";
import { getExperience } from "../../../services";
import { badWordsStoreName, capitalizeFirstLetter } from "../../../utils/utils";
import CTA from "../CTA/CTA";
import "./Cartouche.css";

const Cartouche = () => {
  const { store, country } = useContext(ExperienceContext);
  const { experienceId } = useParams();
  const [m3u8Id, setm3u8Id] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(store);
    getExperience(experienceId as string)
      .then((response) => {
        console.log(response.data.url.split("/").pop());
        setm3u8Id(response.data.url.split("/").pop());
      })
      .catch((error) => {
        console.log(error);
      });
  }, [store]);

  const download = async (filename: string) => {
    const url = await getDownloadURL(ref(storage, `2/${filename}`));
    const response = await fetch(url);
    let blob = await response.blob();
    blob = blob.slice(0, blob.size, "video/mp4");

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const mp4 = m3u8Id.split(".")[0];
    const videoUrl = `https://experience.snippetgroup.com/api/experience/282/${mp4}/tesoro_azul/download`;
    setLoading(true);
    fetch(videoUrl)
      .then(() => {
        download(`${mp4}.mp4`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  return (
    <div className="overlay-end">
      {loading === true && (
        <div className="container-loading">
          <ReactLoading
            className="loading"
            type="spokes"
            color="#7AD6D1"
            width="8rem"
          />
        </div>
      )}
      <div className="social-media-container">
        <div className="social-media-links">
          <div className="icon">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}?shared=true`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  action: `${country}_cta_fb`,
                  category: `${country}`,
                  label: `${experienceId}`, // optional
                });
              }}
            >
              {/* svg */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="69.19"
                height="75.125"
                viewBox="0 0 69.19 75.125"
              >
                <path
                  id="Facebook.svg"
                  className="cls-1"
                  d="M1155.45,754.632a78.425,78.425,0,0,0-2.31,8.56c-1.54,5.675-3.85,11.638-10.39,15.389a8.207,8.207,0,0,0-2.59-3.751,10.774,10.774,0,0,0-13.08,0,8.878,8.878,0,0,0-3.08,6.925v29.816a9.027,9.027,0,0,0,3.08,6.926,10.774,10.774,0,0,0,13.08,0,7.9,7.9,0,0,0,1.82-2.116,9.387,9.387,0,0,0,4.72,2.789c10.48,2.981,17.79,5.194,27.12,5.867a24.436,24.436,0,0,0,9.81-1.924c2.98-1.442,5.57-3.847,6.06-7.406,1.05-8.367,2.98-16.735,3.46-24.334a14.049,14.049,0,0,0-1.93-8.848c-1.73-2.5-4.8-4.136-9.04-4.713a78.649,78.649,0,0,0-14.23-.193c2.79-6.251,4.91-12.215,4.81-17.216-0.1-2.886-.87-5.579-2.89-7.5-1.82-2.02-4.8-2.982-8.56-2.982C1158.14,749.919,1156.51,752.035,1155.45,754.632Zm11.92,0.962a6.673,6.673,0,0,1,1.73,4.809c0.68,4.039-2.21,12.7-7.11,21.352,6.73-.192,14.04-1.058,19.71-0.192,2.41,0.384,5.2,1.25,6.54,3.078,1.06,1.538,1.35,3.654,1.25,6.444-0.38,7.5-2.4,17.12-3.36,23.949-0.29,2.02-1.64,3.366-3.95,4.424a22.313,22.313,0,0,1-7.98,1.635c-14.52-.961-18.08-3.27-26.16-5.674h-0.09c-3.47-.77-4.72-2.693-4.72-5.867V782.621c8.66-4.328,11.83-12.023,13.56-18.371a76.624,76.624,0,0,1,2.22-8.175,3.157,3.157,0,0,1,2.21-2.309c2.69,0.1,5.19.962,6.15,1.828h0Z"
                  transform="translate(-1124 -749.906)"
                  fill="white"
                />
              </svg>

              <span>Facebook</span>
            </a>
          </div>
          <div className="icon">
            <a
              href={`https://wa.me?text=¡Mira qué video tan padre me mandó Marco Antonio Solís! ¡Ya quiero probar el tequila! ${window.location.href}?shared=true`}
              data-action="share/whatsapp/share"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  action: `${country}_cta_wp`,
                  category: `${country}`,
                  label: `${experienceId}`, // optional
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72.75"
                height="69"
                viewBox="0 0 72.75 69"
              >
                <path
                  id="Whatsapp.svg"
                  className="cls-1"
                  d="M1281.22,766.335a1.657,1.657,0,0,0-.34.074c-3.49,1.073-5.97,2.97-7.3,5.44a12.239,12.239,0,0,0-.93,8.306c1.14,5.883,5.21,12.205,10.36,17.471s11.36,9.48,17.28,10.708a12.525,12.525,0,0,0,8.45-.883c2.55-1.333,4.57-3.82,5.83-7.375a1.559,1.559,0,0,0-.64-1.838l-11.17-7.008-0.02-.024c-0.06-.036-0.12-0.069-0.18-0.1a1.51,1.51,0,0,0-.53-0.22,0.408,0.408,0,0,0-.05,0c-0.04-.01-0.09-0.018-0.13-0.025h-0.02a1.59,1.59,0,0,0-.76.147,1.639,1.639,0,0,0-.25.147,0.992,0.992,0,0,0-.14.1,0.322,0.322,0,0,0-.05.074,1.453,1.453,0,0,0-.17.172l-2.45,2.474c-4.45-2.236-7.86-5.985-10.93-11.05l2.25-2.23a1.093,1.093,0,0,0,.13-0.123c0.03-.031.06-0.064,0.09-0.1V780.45a0.716,0.716,0,0,0,.1-0.1v-0.024c0.02-.024.03-0.049,0.05-0.074s0.03-.032.05-0.049c0.02-.04.03-0.081,0.05-0.122v-0.025c0.01-.016.01-0.032,0.02-0.048a0.432,0.432,0,0,0,.05-0.1l0.03-.123v-0.024c0.01-.049.01-0.1,0.02-0.147v-0.342c-0.01-.041-0.01-0.082-0.02-0.123v-0.024a2.413,2.413,0,0,0-.1-0.27v-0.024a1.507,1.507,0,0,0-.1-0.2l-0.02-.049-0.03-.024-6.98-11.467a1.581,1.581,0,0,0-1.3-.76q-0.075,0-.15,0v0Zm18.74-12.724a34.49,34.49,0,0,0-38.33,47.085l-6.2,11.925a1.566,1.566,0,0,0,.67,2.114,1.544,1.544,0,0,0,.94.161l13.2-1.912a34.5,34.5,0,1,0,29.72-59.373h0Zm-0.59,3.08a31.363,31.363,0,1,1-27.53,53.5,1.565,1.565,0,0,0-1.31-.419l-10.91,1.581,5.13-9.864a1.558,1.558,0,0,0,.05-1.349,31.358,31.358,0,0,1,34.57-43.45h0Z"
                  transform="translate(-1255.25 -753)"
                  fill="white"
                />
              </svg>

              <span>Whatsapp</span>
            </a>
          </div>
          <div className="icon">
            <a
              href={`mailto:?subject=¡El Buki me invitó a su backstage!&body=Marco Antonio Solís me hizo un video increíble, invitándome a brindar con su nuevo tequila Tesoro Azul! Da click aquí para verlo: ${window.location.href}?shared=true`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  action: `${country}_cta_email`,
                  category: `${country}`,
                  label: `${experienceId}`, // optional
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="84.75"
                height="51.469"
                viewBox="0 0 84.75 51.469"
              >
                <path
                  id="Email.svg"
                  className="cls-1"
                  d="M1466.76,763.053h0a1.094,1.094,0,0,0-.16-0.605h0V762.3a0.324,0.324,0,0,0-.3-0.3l-0.15-.151a0.3,0.3,0,0,0-.3-0.152h-0.15a0.559,0.559,0,0,0-.46-0.151h-81.59a1.128,1.128,0,0,0-.6.151h-0.15c-0.15,0-.15.152-0.3,0.152a0.147,0.147,0,0,0-.15.151c-0.16.151-.16,0.151-0.31,0.3v0.151h0c0,0.151-.15.3-0.15,0.454h0v48.433a0.919,0.919,0,0,0,.15.606,1.84,1.84,0,0,0,.76.756,0.964,0.964,0,0,0,.75.3h81.59a0.911,0.911,0,0,0,.61-0.151,1.818,1.818,0,0,0,.75-0.757,0.872,0.872,0,0,0,.16-0.606V763.053Zm-57.42,24.822,8.16,7.265a10.426,10.426,0,0,0,7.1,2.725,10.7,10.7,0,0,0,7.1-2.725l8.16-7.265,21.76,22.1h-74.34Zm-24.17,19.979V766.383l21.9,19.525Zm56.66-21.946,21.9-19.525v41.471Zm19.49-21.341-31.73,28.3a7.416,7.416,0,0,1-9.98,0l-32.03-28.3h73.74Z"
                  transform="translate(-1382 -761.531)"
                  fill="white"
                />
              </svg>
              <span>Email</span>
            </a>
          </div>
          <div className="icon">
            <button type="button" onClick={handleDownload}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="68"
                height="75"
                viewBox="0 0 68 75"
              >
                <path
                  id="Download.svg"
                  className="cls-1"
                  d="M1562.87,782.77a4.9,4.9,0,0,0-1.47-3.578,5.048,5.048,0,0,0-7.27,0l-9.8,9.758V755.041a4.9,4.9,0,0,0-1.47-3.578,5.077,5.077,0,0,0-3.68-1.545,4.59,4.59,0,0,0-3.59,1.545,4.977,4.977,0,0,0-1.55,3.578v33.828l-9.72-9.677a5.038,5.038,0,0,0-3.68-1.545,4.706,4.706,0,0,0-3.59,1.545,4.976,4.976,0,0,0,0,7.237l18.54,18.459a4.931,4.931,0,0,0,3.59,1.463,5.273,5.273,0,0,0,3.68-1.463l18.54-18.459a4.953,4.953,0,0,0,1.47-3.659m9.31,37V810.5a4.9,4.9,0,0,0-1.47-3.578,5.054,5.054,0,0,0-3.67-1.545,4.727,4.727,0,0,0-3.6,1.545,4.974,4.974,0,0,0-1.55,3.578v4.147h-45.42V810.5a4.9,4.9,0,0,0-1.47-3.578,5.054,5.054,0,0,0-3.67-1.545,4.727,4.727,0,0,0-3.6,1.545,4.974,4.974,0,0,0-1.55,3.578v9.27a5.143,5.143,0,0,0,5.15,5.122h55.71A5.012,5.012,0,0,0,1572.18,819.769Z"
                  transform="translate(-1506.19 -749.906)"
                  fill="white"
                />
              </svg>

              <span style={{ fontSize: " 1.5em", fontWeight: "600" }}>
                Descargar
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="overlay-end-container">
        <div className="cartouche-container">
          <p
            style={{
              fontSize: "6em",
              fontFamily: "Helvetica",
              fontWeight: "bold",
              color: "#FFFFFF",
              paddingBottom: "0.3em",
            }}
          >
            {store.group && store.group}
          </p>
          <p
            style={{
              fontSize: "4.5em",
              fontFamily: "Helvetica",
              fontWeight: "bold",
              color: "#FFFFFF",
              lineHeight: "0.9",
              marginBottom: "4%",
            }}
          >
            {store.name && badWordsStoreName(store.name) === "¡Sigue brindando!"
              ? badWordsStoreName(store.name)
              : `${capitalizeFirstLetter(badWordsStoreName(store.name))},`}
          </p>
          <p
            style={{
              fontSize: "2.6em",
              fontFamily: "Helvetica",
              fontWeight: "lighter",
              lineHeight: "1.2em",
              color: "#FFFFFF",
            }}
          >
            {badWordsStoreName(store.name) === "¡Sigue brindando!"
              ? "No olvides compartir"
              : "Sigue brindando y no olvides compartir"}
            <br />
            la experiencia que hoy vivimos juntos,
            <br />
            yo siempre la llevaré en mi corazón.
          </p>
          <div>
            <CTA />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cartouche;
