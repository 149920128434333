import React, { FC } from "react";
import "./App.css";
import ReactGA from "react-ga4";
import AppRouter from "./router/AppRouter";

ReactGA.initialize([
  {
    trackingId: "UA-258892052-1",
  },
]);

const App: FC = () => {
  return <AppRouter />;
};

export default App;
