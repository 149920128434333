import "./Modal.css";

const ModalAge = ({ setOpenModal }: any) => {
  return (
    <div className="container-background-modal">
      <div className="container-background">
        <div className="container-modal">
          <div>
            <p className="title-modal">Tus datos, siempre protegidos</p>
          </div>
          <div className="container-btns-modal">
            <p className="information-modal">
              Este sitio utiliza cookies de Google para prestar sus servicios y
              para analizar su tráfico. Tu dirección IP y user-agent se
              comparten con Google, junto con las métricas de rendimiento y de
              seguridad, para garantizar la calidad del servicio, generar
              estadísticas de uso y detectar y solucionar abusos.
            </p>
            <div className="btn-container">
              <button
                className="btn-modal"
                type="submit"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                ACEPTO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAge;
