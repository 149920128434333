import { useContext } from "react";
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import { ExperienceContext } from "../../../contexts/experience.context";

const CTA = () => {
  const { country, originalCountry } = useContext(ExperienceContext);
  const { experienceId } = useParams();

  return (
    <div>
      <button
        type="button"
        style={{
          // display: 'table-cell',
          verticalAlign: "middle",
          backgroundColor: "#91E1E4",
          padding: "0.5em 2.5em",
          color: "black",
          fontFamily: "Helvetica",
          fontSize: "3em",
          border: "0px",
          borderRadius: "18px",
          pointerEvents: "auto",
          marginTop: "1em",
        }}
        onClick={() => {
          // @ts-ignore
          ReactGA.event({
            action: `${country}_cta_web`,
            category: `${country}`,
            label: `${experienceId}`, // optional
          });
          if (originalCountry.toLowerCase() === "united states") {
            window.open("https://tesoroazul.com/", "_blank");
          } else {
            window.open(
              "https://www.walmart.com.mx/ip/licores-y-destilados/tequila-tesoro-azul-cristalino-750-ml-tesoro-azul-cristalino/00080822785727?from=searchResults",
              "_blank",
            );
          }
        }}
      >
        Quiero mi <br />
        <strong>Tesoro Azul</strong>
      </button>
    </div>
  );
};

export default CTA;
